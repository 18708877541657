@import url("https://fonts.googleapis.com/css2?family=Archivo:wght@400;500;600;700;800&display=swap");
body {
  margin: 0px;
  padding: 0px;
  font-family: "Archivo", sans-serif !important;
}
.container {
  width: 90%;
  margin: 0 auto;
}
p {
  margin-bottom: 0;
}
html,
body {
  overflow-x: hidden;
}
body {
  background: url("../assets/images/hero-bg.png") !important;
  background-repeat: no-repeat !important;
  background-position: 0% 1.65% !important;
}
@media (max-width: 768px) {
  html {
    font-size: 15px;
  }
}
/* @media(max-width:576px){
	.filter-darken{
		backdrop-filter: brightness(80%);
	}
} */

@media only screen and (max-width: 991px) {
  div#div-btn-suc-chosanplan {
    display: block !important;
  }

  a.text-decoration-none {
    display: block !important;
  }

  button#btn-suc-chosanplan {
    display: block !important;
    padding: 9px 24px 8px 43px !important;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .col-footermobile {
    justify-content: center;
    margin-top: 20px;
  }

  p.text-copy-right {
    text-align: center;
  }

  p.text-copy-right {
    text-align: center;
  }

  .footer-mobile-media-icon {
    justify-content: center;
  }

  .col-footermobile {
    justify-content: center !important;
  }

  .footer-media-d-none {
    display: none !important;
  }
}
main {
  margin: 0px;
  padding: 0px;
}

.btn {
  transition: transform 0.5s !important;
}
.btn:hover {
  transform: scale(1.04);
}

:root {
  --code-color: darkred;
  --code-bg-color: #f6f6f6;
  --code-font-size: 14px;
  --code-line-height: 1.4;
  --scroll-bar-color: #c5c5c5;
  --scroll-bar-bg-color: #f6f6f6;
}

pre {
  color: var(--code-color);
  font-size: var(--code-font-size);
  line-height: var(--code-line-height);
  background-color: var(--code-bg-color);
}

.code-block {
  max-height: 100px;
  overflow: auto;
  padding: 8px 7px 5px 15px;
  margin: 0px 0px 0px 0px;
  border-radius: 7px;
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--scroll-bar-color) var(--scroll-bar-bg-color);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: var(--scroll-bar-bg-color);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--scroll-bar-color);
  border-radius: 20px;
  border: 3px solid var(--scroll-bar-bg-color);
}

.clr-green {
  color: #81bc05;
  width: auto;
  padding-left: 0px;
}

li.hidden_li_plan2 {
  display: none;
}

li.hidden_li_plan3 {
  display: none;
}

/* i.clr-md-green {
	width: auto;
} */

@media (max-width: 768px) {
  .clr-md-green {
    color: #0c6400;
  }
}

@media (max-width: 769px) {
  /* .clr-md-green {
		color: #81bc05;
	} */
  span.mobile-color {
    color: #047835 !important;
  }
}

@font-face {
  font-family: "AvertaStd";
  src: url("../fonts/AvertaStd-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Averta-Black";
  src: url("../fonts/Averta-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Averta-ExtraBold";
  src: url("../fonts/Averta-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Averta-regular";
  src: url("../fonts/Averta-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Averta-Bold";
  src: url("../fonts/Averta-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Averta-Semibold";
  src: url("../fonts/Averta-Semibold.ttf") format("truetype");
}

/* img#banner-img {
    position: absolute;
    bottom: 0;
    right: 125px;
} */

.hero-banner {
  /* background-image: url('../assets/hero-banner.jpg'); */
  /* background-image: url('https://nextdaysite.com/clubconnect/marketing01.jpeg'); */
  background-size: cover;
  background-position: center center;
}

.aboutUs-main-banner {
  background-image: url("../assets/hero-banner.jpg");
  background-size: cover;
  background-position: center center;
}

.contactUs-main-banner {
  background-image: url("../assets/hero-banner-contactUs.jpg");
  background-size: cover;
  background-position: top center;
}

.owl-nav {
  display: none;
}

.nav-link.active {
  color: #057836 !important;
}

.nav-link {
  color: #020202 !important;
}

@media only screen and (max-width: 1199px) and (min-width: 992px) {
  .col-banner-button {
    width: 20%;
  }

  div#banner-text-div {
    width: 60%;
  }

  iframe.w-100.i-frame {
    height: 284px !important;
  }

  .hero-main-banner {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@media (max-width: 767px) {
  button#btn-jn-club {
    width: 50% !important;
    padding: 10px;
  }
}

@media (max-width: 768px) {
  .mt-15neg {
    margin-top: -15px;
  }

  .contactUs-main-banner {
    background-position: top left 30%;
  }
}
@media only screen and (max-width: 1200px) and (min-width: 992px) {
  /* div#div-grwng-com-info {
		height: 513px!important;
	} */

  img.logos {
    height: 7rem !important;
  }
}

@media only screen and (max-width: 991px) {
  div#div-grwng-com-info {
    height: 100% !important;
  }

  .hero-main-banner {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }

  img.logos {
    height: 7rem !important;
  }

  img.img-1121 {
    left: -127px !important;
  }

  h1:not(.slider-header) {
    text-shadow: 0px 3px 5px rgba(255, 255, 255, 1);
  }
}

@media only screen and (max-width: 800px) {
  .hero-banner {
    /* background-image: url('../assets/hero-banner.jpg'); */
    background-size: cover;
    background-position: center left 60%;
  }
}
.sidebar {
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
  transform: translateX(-100%);
  max-width: 0px;
  padding: 0px;
  transition-duration: 500ms;
}

.sidebar.sidebar-active {
  transform: translateX(0);
  max-width: 100%;
  padding: 2rem;
}

@media only screen and (min-width: 992px) {
  .sidebar.sidebar-active {
    transform: translateX(-100%);
    padding: 2rem;
  }
}
@media only screen and (min-width: 760px) and (max-width: 991px) {
  .sidebar.sidebar-active {
    transform: translateX(0);
    max-width: 50%;
    padding: 2rem;
  }
}

@media (min-width: 544px) {
  h1 {
    font-size: 1.5rem;
  }

  /*1rem = 16px*/
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
  h1 {
    font-size: 2rem;
  }

  /*1rem = 16px*/
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  h1 {
    font-size: 2.5rem;
  }

  /*1rem = 16px*/
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  h1 {
    font-size: 3rem;
  }

  /*1rem = 16px*/
}

/*
::::::::::::::::::::::::::::::::::::::::::::::::::::
Custom media queries
*/

/* Set width to make card deck cards 100% width */
@media (min-width: 950px) and (max-width: 1100px) {
  h1 {
    font-size: 2.75rem;
    /* color: red; */
  }
}

@media (min-width: 1200px) {
  .fs-3 {
    font-size: 1.4rem !important;
  }
}

.fs-xxl {
  font-size: 3rem;
}
.font-averta {
  font-family: "AvertaStd";
}

.averta-bold {
  font-family: "Averta-Bold";
}

.averta-extraBold {
  font-family: "Averta-ExtraBold";
}

.averta-regular {
  font-family: "Averta-regular";
}

.averta-black {
  font-family: "Averta-Black";
}

.averta-semibold {
  font-family: "Averta-Semibold";
}

.bg-primary {
  background-color: #81bc05 !important;
}

.bg-light {
  background-color: #f6f6f6 !important;
}
/* override bootstrap primary value to light green */
.btn-primary {
  background-color: #81bc05;
  border-color: #81bc05;
}
.btn-primary:hover {
  background-color: #81bc05;
  border-color: #81bc05;
}
.btn-primary:active {
  background-color: #81bc05;
  border-color: #81bc05;
}
.btn-primary:focus {
  background-color: #81bc05;
  border-color: #81bc05;
}
/* orange */
.btn-orange {
  background-color: #fd8204;
  border-color: #fd8204;
  color: white;
}
.btn-orange:hover {
  background-color: #fd8204;
  border-color: #fd8204;
  color: white;
}
.btn-orange:active {
  background-color: #fd8204;
  border-color: #fd8204;
  color: white;
}
.btn-orange:focus {
  background-color: #fd8204;
  border-color: #fd8204;
  color: white;
}

/* primary but has a darker value */
/* !!! optimize code using scss */
.btn-primary-alt {
  border-color: #047835;
  background-color: #047835;
  color: white;
}
.btn-primary-alt:hover {
  border-color: #047835;
  background-color: #047835;
  color: white;
}
.btn-primary-alt:visited {
  border-color: #047835;
  background-color: #047835;
  color: white;
}
.btn-primary-alt:active {
  border-color: #047835;
  background-color: #047835;
  color: white;
}
.btn-primary-alt:focus {
  border-color: #047835;
  background-color: #047835;
  color: white;
}

/* dark  */
.btn-dark {
  background-color: #051c2e;
  border-color: #051c2e;
}
.btn-dark:hover {
  background-color: #051c2e;
  border-color: #051c2e;
}

.accordion-button:focus {
  color: #077935;
}
.accordion-button:active {
  color: #077935;
}
.accordion-button:visited {
  color: #077935;
}
.accordion-button {
  font-weight: bold;
}

a.plan_readmore {
  position: absolute;
  bottom: 21px;
  left: 40px;
}

.accordion-button:not(.collapsed) {
  color: #077935 !important;
  background-color: unset;
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0
    var(--bs-accordion-border-color);
}

svg.media-icon {
  font-size: 23px;
  margin: 3px;
}

button.btn-indicator {
  border-top: none !important;
  border-bottom: none !important;
  height: 10px !important;
  border-radius: 10px !important;
  width: 10px !important;
}

button.btn-orange-border {
  border: 2px solid #fd8205 !important;
  color: #fd8205;
}

@media (max-width: 767px) {
  button.btn-mobile-small {
    padding: 10px 15px 10px 15px !important;
    font-size: 14px !important;
  }
}

.ant-modal-content {
  padding: 0px !important;
}
